import { graphql, PageProps } from "gatsby"
import React from "react"

import { CategoryPageContext } from "../interfaces/pageContext"
import CollectionPage from "./CollectionPage"

export type CategoryLuxuryPageProps = PageProps<any, CategoryPageContext>

const CategoryLuxuryPage: React.FC<CategoryLuxuryPageProps> = (props) => {
  const { pageContext } = props
  const { category } = pageContext
  return (
    <CollectionPage
      name={`Luxus ${category}`}
      description={`Luxus ${category} online kaufen bei buybags.de. Entdecke jetzt luxuriöse weiße, braune und schwarze Designer Taschen von MCM, Chloé, DOLCE&GABBANA und Balenciaga.`}
      primaryKeyword={`Luxus ${category} kaufen`}
      secondaryKeyword={`Designertaschen Online Shop`}
      {...props}
    />
  )
}

export default CategoryLuxuryPage

export const query = graphql`
  query CategoryLuxuryPageQuery($category: String!, $skip: Int!, $limit: Int!) {
    allProductsCsv(
      filter: { fields: { category: { eq: $category }, luxury: { eq: true } } }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          fields {
            brand
            colors
            deepLink
            deliveryCost
            deliveryTime
            description
            gtin
            images {
              alt
              src
              title
            }
            material
            name
            price
            priceBefore
            sale
            slug
          }
        }
      }
      totalCount
    }
  }
`
